
import { computed, defineComponent, inject, onBeforeMount } from 'vue'
import RankingClasificationBox from '@/components/RankingClasificationBox.vue'
import i18n from '@/i18n'
export default defineComponent({
    components: { RankingClasificationBox },
    setup() {
        const configurationStore: any = inject('configurationStore')
        const authStore: any = inject('authState')
        const userData = computed(() => authStore.state.userData)
        const ranking = computed(() => configurationStore.state.ranking.ranking)
        const topRanking = computed(
            () => configurationStore.state.ranking.topRanking
        )
        const getRanking = () => {
            configurationStore.getRanking()
        }
        const getTopRanking = () => {
            configurationStore.getTopRanking()
        }

        const getLevelStatus = (type: string) => {
            switch (type) {
                case 'REGULAR':
                    return 'PádaWAT'
                case 'PRO':
                    return 'WATpro'
                case 'GURU':
                    return 'WATguru'

                default:
                    break
            }
        }

        onBeforeMount(() => {
            getRanking()
            getTopRanking()
        })

        return {
            ranking,
            userData,
            getLevelStatus,
            topRanking,
        }
    },
})
